import { h } from 'preact'
import {useRef, useState, useEffect} from 'preact/hooks'
import Button from '../components/Button'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import items from '../data/constructions'
import modules from '../data/modules'
import useCarousel from '../utils/carousel'
import useLazyTrigger from '../utils/lazyTrigger'
import './Projects.sass'

const Projects = ({dispatch}) => {
  const [hasWebP, setHasWebP] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const [current, setCurrent] = useState(0)
  const modulesWindow = sliceModules(modules, current)
  const ref = useRef(null)
  const carousel = useCarousel(items, 2)

  useEffect(() => {
    Modernizr.on('webp', function(result) {
      if (result) {
        setHasWebP(true)
      } else {
        setHasWebP(false)
      }
    })
  }, [])

  function handleLeft(event) {
    setCurrent(current => current > 0 ? current - 1 : modules.length - 1)
  }

  function handleRight(event) {
    setCurrent(current => current < modules.length - 1 ? current + 1 : 0)
  }

  function handleClick(index) {
    dispatch({
      type: 'showLightBox',
      payload: {
        items: modules,
        current: current + index,
        hasWebP: hasWebP
      }
    })
  }

  useLazyTrigger(ref, () => setIsReady(true))

  return (
    <Screen id="projects" ref={ref} className="Projects">
      <div className="Projects-decoration-12"></div>
      <div className="Projects-decoration-13"></div>
      <div className="Projects-decoration-14"></div>
      <div className="Projects-inner">
        <Container>
          <div className="Projects-heading">
            <Heading>
              У вас есть возможность сделать <br className="Projects-br"/>
              свой проект Ниндзя-парк
            </Heading>
          </div>
          <div className="Projects-boxes Projects-boxes--outer">
            <div className="Projects-decoration-4"></div>
            <div className="Projects-decoration-1"></div>
            <div className="Projects-decoration-2"></div>
            <div className="Projects-decoration-3"></div>
            <div className="Projects-decoration-5"></div>
            <div className="Projects-box">
              <div
                className="Projects-image Projects-image--full"
                style={selectImage(modulesWindow, isReady, hasWebP, 0)}
                onClick={e => handleClick(0)}
              />
            </div>
            <div className="Projects-boxes Projects-boxes--inner">
              <div className="Projects-box">
                <div className="Projects-image" style={selectImage(modulesWindow, isReady, hasWebP, 1)} onClick={e => handleClick(1)}/>
              </div>
              <div className="Projects-box">
                <div className="Projects-image" style={selectImage(modulesWindow, isReady, hasWebP, 2)} onClick={e => handleClick(2)}/>
              </div>
              <div className="Projects-box">
                <div className="Projects-image" style={selectImage(modulesWindow, isReady, hasWebP, 3)} onClick={e => handleClick(3)}/>
              </div>
              <div className="Projects-box">
                <div className="Projects-image" style={selectImage(modulesWindow, isReady, hasWebP, 4)} onClick={e => handleClick(4)}/>
              </div>
            </div>
            <div className="Projects-left" onClick={handleLeft}></div>
            <div className="Projects-right" onClick={handleRight}></div>
          </div>
          <div className="Projects-heading">
            <Heading shaded>
              Примеры готовых вариантов конструкций
            </Heading>
          </div>
          <div className="Projects-carousel">
            <div className="Projects-decoration-6"></div>
            <div className="Projects-decoration-7"></div>
            <div className="Projects-decoration-8"></div>
            <div className="Projects-decoration-9"></div>
            <div className="Projects-decoration-10"></div>
            <div
              className="Projects-showcase"
              onTouchStart={carousel.handleTouchStart}
              onTouchMove={carousel.handleTouchMove}
              onTouchEnd={carousel.handleTouchEnd}
            >
              <div className="Projects-items" style={{
                width: `${100 * items.length}%`,
                transform: `translateX(${carousel.offset}%) translateX(${carousel.isMoving ? carousel.delta : 0}px)`,
                transition: carousel.isMoving ? 'none' : 'transform ease-out 0.5s'
              }}>
                {items.map((src, index) => (
                  <div key={index} className="Projects-item" style={{
                    width: `${100 / items.length}%`,
                    opacity: index === carousel.current ? 1 : 0.5,
                    transform: `scale(${index === carousel.current ? 1 : 0.85})`
                  }}>
                    <picture>
                      <source srcset={src.webp} type="image/webp"/>
                      <source srcset={src.jpg} type="image/jpeg"/>
                      <img src={src.jpg} alt={index + 1} className="Projects-item-img" loading="lazy"/>
                    </picture>
                  </div>
                ))}
              </div>
            </div>
            <div className="Projects-left" onClick={carousel.handleLeft}></div>
            <div className="Projects-right" onClick={carousel.handleRight}></div>
          </div>
          <div className="Projects-download">
            <div className="Projects-decoration-11">
              <Button className="Projects-download-btn">
                Скачать предложение
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </Screen>
  )
}

function sliceModules(modules, current) {
  return modules.concat(modules).slice(current, current + 5)
}

function selectImage(modules, isReady, hasWebP, index) {
  if (isReady && hasWebP !== null) {
    return hasWebP ? {
      backgroundImage: `url(${modules[index].thumb.webp})`
    } : {
      backgroundImage: `url(${modules[index].thumb.jpg})`
    }
  } else {
    return {
      backgroundImage: 'none'
    }
  }
}

export default Projects
