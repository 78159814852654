import {useState, useEffect} from 'preact/hooks'

function useCarousel(items, preloadCount = 1) {
  const [isLoaded, setIsLoaded] = useState(items.map((_, i) => i < preloadCount))
  const [current, setCurrent] = useState(0)
  const [touch, setTouch] = useState({
    isMoving: false,
    startPos: 0,
    currentPos: 0
  })
  const offset = Math.round(-1000000 / items.length * current) / 10000
  const delta = touch.currentPos - touch.startPos

  function handleRight(event) {
    event.preventDefault()
    setCurrent(x => x < items.length - 1 ? x + 1 : x)
  }

  function handleLeft(event) {
    event.preventDefault()
    setCurrent(x => x > 0 ? x - 1 : x)
  }

  function handleTouchStart(event) {
    const {pageX} = event.touches[0]
    setTouch((touch) => ({
      isMoving: true,
      startPos: pageX,
      currentPos: pageX
    }))
  }

  function handleTouchMove(event) {
    const {pageX} = event.touches[0]
    setTouch((touch) => ({
      ...touch,
      currentPos: pageX
    }))
  }

  function handleTouchEnd(event) {
    if (delta > 100) {
      setCurrent(x => x > 0 ? x - 1 : x)
    } else if (delta < -100) {
      setCurrent(x => x < items.length - 1 ? x + 1 : x)
    }
    setTouch((touch) => ({
      ...touch,
      isMoving: false
    }))
  }

  useEffect(() => {
    if (!isLoaded[current]) {
      setIsLoaded(list => list.map((_, i) => _ || i === current))
    }
    if (preloadCount > 1) {
      if (!isLoaded[current + 1]) {
        setIsLoaded(list => list.map((_, i) => _ || i === current + 1))
      }
    }
  }, [current, isLoaded, setIsLoaded, preloadCount])

  return {
    isLoaded, current, offset, delta, setCurrent,
    isMoving: touch.isMoving,
    handleLeft, handleRight,
    handleTouchStart, handleTouchMove, handleTouchEnd
  }
}

export default useCarousel
