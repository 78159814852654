export function joinClasses(classNames) {
  return classNames
    .filter(isValidClassName)
    .join(' ')
}

export function buildClass(style, props, mods) {
  return joinClasses([
    applyExclusiveMods(style, props, mods[0], mods[1]),
    applyInclusiveMods(style, props, mods[0], mods[2]),
    props.className
  ])
}

function applyExclusiveMods(style, props, block, mods) {
  if (Array.isArray(mods)) {
    const enabledMod = mods.find(mod => props[mod])
    if (typeof enabledMod !== 'undefined') {
      const className = block + capitalizeFirstLetter(enabledMod)
      return typeof style[className] !== 'undefined'
        ? style[className]
        : style[block]
    }
    return style[block]
  }
  return style[block]
}

function applyInclusiveMods(style, props, block, mods) {
  if (Array.isArray(mods)) {
    return mods.filter(mod => props[mod]).map(mod => {
      const className = typeof props[mod] === 'string'
        ? block + capitalizeFirstLetter(mod) + capitalizeFirstLetter(props[mod])
        : block + capitalizeFirstLetter(mod)
      return style[className]
    }).join(' ')
  }
}

function isValidClassName(className) {
  return (
    typeof className === 'string' &&
    className.length > 0
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
