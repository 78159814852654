import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Container.sass'

const Container = ({children, ...props}) => (
  <div className={buildClass(style, props, ['container', ['alt']])}>
    {children}
  </div>
)

export default Container
