import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Logo.sass'
import logoImage from '../assets/images/logo.png'

const Logo = (props) => (
  <a href="/" className={buildClass(style, props, ['logo'])}>
    <img src={logoImage} alt="Outride Ninja Park" className={style.logoPicture}/>
  </a>
)

export default Logo
