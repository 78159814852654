import { h } from 'preact'
import {useRef, useState, useEffect} from 'preact/hooks'
import Button from '../components/Button'
import sendOrder from '../utils/sendOrder'
import './PopupForm.sass'

const PopupForm = ({form, dispatch, isFormOpen, isPopupOpen}) => {
  const [clientEmail, setClientEmail] = useState('')
  const [clientName, setClientName] = useState('')
  const [clientTel, setClientTel] = useState('')
  const [isClosing, setIsClosing] = useState(false)
  const nameInput = useRef(null)

  useEffect(() => {
    if (isFormOpen) {
      nameInput.current.focus()
    }
  }, [isFormOpen])

  function handleChange(event) {
    switch (event.target.name) {
      case 'clientEmail':
        setClientEmail(event.target.value); break
      case 'clientName':
        setClientName(event.target.value); break
      case 'clientTel':
        setClientTel(event.target.value); break
      default:
        break
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    sendOrder({
      name: clientName,
      tel: clientTel,
      email: clientEmail,
      source: form
    }).then((response) => {
      if (response.status < 300) {
        dispatch({
          type: 'showMessage',
          payload: {
            type: 'success',
            title: 'Заявка отправлена!',
            description: 'Наш менеджер свяжется с вами в течении дня. Ожидайте.'
          }
        })
      } else {
        dispatch({
          type: 'showMessage',
          payload: {
            type: 'error',
            title: 'Ошибка!',
            description: 'Неудалось отправить заявку! Попробуйте снова!'
          }
        })
      }
    }).catch(() => {
      dispatch({
        type: 'showMessage',
        payload: {
          type: 'error',
          title: 'Ошибка!',
          description: 'Неудалось отправить заявку!'
        }
      })
    })
  }

  function handleFormClick(event) {
    event.stopPropagation()
  }

  function closeWindow() {
    setIsClosing(true)
    setTimeout(() => {
      dispatch({type: 'closeForm'})
      setIsClosing(false)
    }, 400)
  }

  return (
    <div className={getClassName(isFormOpen, isPopupOpen, isClosing)} onClick={closeWindow}>
      <form className="PopupForm-form" onSubmit={handleSubmit} onClick={handleFormClick}>
        <div className="PopupForm-border"/>
        <div className="PopupForm-content">
          <h2 className="PopupForm-heading">
            Оставьте свои <strong className="ninja-strong">контакты</strong>
          </h2>
          <div className="PopupForm-description">
            И мы свяжемся с вами в течении дня
          </div>
          <div className="PopupForm-group">
            <div className="PopupForm-icon PopupForm-icon--user"></div>
            <label htmlFor="client-name-1" className="PopupForm-label">
              Имя, Фамилия:
            </label>
            <input
              id="client-name-1"
              name="clientName"
              type="text"
              ref={nameInput}
              value={clientName}
              placeholder="Виталий Миронов"
              className="PopupForm-input"
              onInput={handleChange}
              required
            />
          </div>
          <div className="PopupForm-group">
            <div className="PopupForm-icon PopupForm-icon--phone"></div>
            <label htmlFor="client-tel-1" className="PopupForm-label">
              Телефон:
            </label>
            <input
              id="client-tel-1"
              name="clientTel"
              type="tel"
              value={clientTel}
              placeholder="+7 777 999 99 99"
              className="PopupForm-input"
              onInput={handleChange}
              required
            />
          </div>
          <div className="PopupForm-group">
            <div className="PopupForm-icon PopupForm-icon--mail"></div>
            <label htmlFor="client-email-1" className="PopupForm-label">
              Email:
            </label>
            <input
              id="client-email-1"
              name="clientEmail"
              type="email"
              value={clientEmail}
              placeholder="vitaliy.mironov@gamil.com"
              className="PopupForm-input"
              onInput={handleChange}
              required
            />
          </div>
          <div className="PopupForm-order">
            <Button className="PopupForm-send">
              Отправить
            </Button>
          </div>
        </div>
        <div className="PopupForm-close" onClick={closeWindow}>
          <div className="PopupForm-close-icon"/>
        </div>
      </form>
    </div>
  )
}

function getClassName(isFormOpen, isPopupOpen, isClosing) {
  if (isFormOpen) {
    if (isClosing) {
      return 'PopupForm PopupForm--closing'
    }
    return isPopupOpen
      ? 'PopupForm PopupForm--blured'
      : 'PopupForm PopupForm--open'
  }
  return 'PopupForm'
}

export default PopupForm
