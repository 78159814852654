import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Button.sass'

const mods = ['button', ['alt', 'shaded', 'inverted']]

const Button = ({children, onClick, ...props}) => (
  <div className={buildClass(style, props, mods)}>
    <button className={style.buttonElement} onClick={onClick}>
      {children}
    </button>
  </div>
)

export default Button
