import { h } from 'preact'
import {useRef} from 'preact/hooks'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import useLazyTrigger from '../utils/lazyTrigger'
import './Numbers.sass'

const Numbers = () => {
  const ref = useRef(null)

  useLazyTrigger(ref, () => import('./NumbersLazy.sass'))

  return (
    <Screen id="numbers" ref={ref} className="Numbers">
      <div className="Numbers-decoration-2"></div>
      <div className="Numbers-decoration-3"></div>
      <Container className="Numbers-inner">
        <Heading bar="right">
          <div className="Numbers-decoration-1"></div>
          Экономика бизнеса
        </Heading>
        <ul className="Numbers-list">
          <li className="Numbers-item">
            <div className="Numbers-title">
              <span className="Numbers-number">9</span> мес.
            </div>
            <div className="Numbers-text">
              Среднее число рабочих месяцев в году <br/>
              с учетом сезонности:
            </div>
          </li>
          <li className="Numbers-item">
            <div className="Numbers-title">
              <span className="Numbers-number">50</span> рабочих&nbsp;дней
            </div>
            <div className="Numbers-text">
              Делаем всё за:
            </div>
          </li>
          <li className="Numbers-item">
            <div className="Numbers-title">
              <span className="Numbers-number">5 085 000</span> руб.
            </div>
            <div className="Numbers-text">
              Средняя прибыль в год
            </div>
          </li>
          <li className="Numbers-item">
            <div className="Numbers-title">
              <span className="Numbers-number">10-12</span> мес.
            </div>
            <div className="Numbers-text">
              Окупаемость средней <br/>
              стоимости проекта <br/>
              ниндзя парка:
            </div>
          </li>
        </ul>
      </Container>
    </Screen>
  )
}

export default Numbers
