import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Screen.sass'

const Screen = ({children, id, ...props}) => (
  <section id={id} className={buildClass(style, props, ['screen'])}>
    {children}
  </section>
)

export default Screen
