import { h } from 'preact'
import {useRef} from 'preact/hooks'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import useLazyTrigger from '../utils/lazyTrigger'
import './Requirements.sass'

const Requirements = () => {
  const ref = useRef(null)

  useLazyTrigger(ref, () => import('./RequirementsLazy.sass'))

  return (
    <Screen id="requirements" ref={ref} className="Requirements">
      <div className="Requirements-decoration-3"></div>
      <div className="Requirements-decoration-4"></div>
      <Container className="Requirements-inner">
        <Heading bar="right">
          <div className="Requirements-decoration-1"></div>
          Общие требования
        </Heading>
        <ul className="Requirements-list">
          <li className="Requirements-item">
            Высота потолков в: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">4,5 </span>
                метра
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Нагрузка пола в: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">300 </span>
                кг/м2
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Площадь от: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">50 </span>
                м2
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Высота потолков в: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">4,5 </span>
                метра
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Высота потолков в: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">4,5 </span>
                метра
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Нагрузка пола в: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">300 </span>
                кг/м2
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Площадь от: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">50 </span>
                м2
              </span>
            </span>
          </li>
          <li className="Requirements-item">
            Высота потолков в: <span className="Requirements-box">
              <span className="Requirements-box-inner">
                <span className="Requirements-value">4,5 </span>
                метра
              </span>
            </span>
          </li>
        </ul>
      </Container>
      <div className="Requirements-decoration-2"></div>
    </Screen>
  )
}

export default Requirements
