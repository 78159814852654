import { h } from 'preact'
import './PopupContainer.sass'

const PopupContainer = ({children, isOpen}) => {
  return (
    <div className={isOpen ? 'PopupContainer PopupContainer--open' : 'PopupContainer'}>
      <div className="PopupContainer-shadow"/>
      {children}
    </div>
  )
}

export default PopupContainer
