import { h } from 'preact'
import Logo from '../components/Logo'
import Button from '../components/Button'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import './Main.sass'

const showForm =  (source) => ({
  type: 'showForm',
  payload: source
})

const Main = ({dispatch}) => (
  <Screen id="main" className="Main">
    <div className="Main-background"></div>
    <div className="Main-decoration"></div>
    <Container>
      <div className="Main-header">
        <Logo className="Main-logo"/>
        <div className="Main-feedback">
          <Button className="Main-order" onClick={() => dispatch(showForm('Главный экран / оставить заявку'))}>
            <i className="Main-edit-icon"></i>
            Оставить заявку
          </Button>
          <div className="Main-tel">
            <i className="Main-tel-icon"></i>
            <span className="Main-tel-number">
              +7(495) 188-72-72
            </span>
          </div>
        </div>
      </div>
      <div className="Main-body">
        <Heading className="Main-heading" main>
          <span className="Main-decoration-2"></span>
          <span className="Main-decoration-1"></span>
          <span className="Main-decoration-3"></span>
          <span className="Main-heading-text">
            Производство <br/>
            Ниндзя-Парков по <br/>
            всей  России
          </span>
        </Heading>
        <div className="Main-cta">
          <Button className="Main-cta-1" onClick={() => dispatch(showForm('Главный экран / готовые решения'))}>
            Готовые решения
          </Button>
          <Button className="Main-cta-2" onClick={() => dispatch(showForm('Главный экран / под ключ'))} alt>
            Под ключ
          </Button>
        </div>
      </div>
    </Container>
  </Screen>
)

export default Main
