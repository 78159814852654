import { h } from 'preact'
import {useRef, useState, useEffect} from 'preact/hooks'
import Button from '../components/Button'
import Screen from '../components/Screen'
import Container from '../components/Container'
import useLazyTrigger from '../utils/lazyTrigger'
import parkImage from '../assets/images/offer.jpg'
import parkImageWebP from '../assets/images/offer.webp'
import parkImageMin from '../assets/images/offer-mini.jpg'
import sendOrder from '../utils/sendOrder'
import './Offer.sass'


const Offer = ({dispatch}) => {
  const [isReady, setIsReady] = useState(false)
  const [hasWebP, setHasWebP] = useState(null)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [tel, setTel] = useState('')
  const ref = useRef(null)
  const parkImage = isReady ? hasWebP ? parkImageWebP : parkImage : parkImageMin

  useEffect(() => {
    Modernizr.on('webp', function(result) {
      if (result) {
        setHasWebP(true)
      } else {
        setHasWebP(false)
      }
    })
  }, [])

  useLazyTrigger(ref, () => setIsReady(true))

  function handleChange(event) {
    switch (event.target.name) {
      case 'email':
        setEmail(event.target.value); break
      case 'name':
        setName(event.target.value); break
      case 'tel':
        setTel(event.target.value); break
      default:
        break
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    sendOrder({
      name: name,
      tel: tel,
      email: email,
      source: 'В вашем городе еще нет ниндзя-парка?'
    }).then((response) => {
      if (response.status < 300) {
        dispatch({
          type: 'showMessage',
          payload: {
            type: 'success',
            title: 'Заявка отправлена!',
            description: 'Наш менеджер свяжется с вами в течении дня. Ожидайте.'
          }
        })
      } else {
        dispatch({
          type: 'showMessage',
          payload: {
            type: 'error',
            title: 'Ошибка!',
            description: 'Неудалось отправить заявку! Попробуйте снова!'
          }
        })
      }
    }).catch(() => {
      dispatch({
        type: 'showMessage',
        payload: {
          type: 'error',
          title: 'Ошибка!',
          description: 'Неудалось отправить заявку!'
        }
      })
    })
  }

  return (
    <Screen id="offer" ref={ref} className="Offer">
      <div className="Offer-decoration-6"></div>
      <div className="Offer-decoration-7"></div>
      <div className="Offer-decoration-10"></div>
      <div className="Offer-decoration-8"></div>
      <div className="Offer-decoration-9"></div>
      <Container className="Offer-inner">
        <form className="Offer-form" onSubmit={handleSubmit}>
          <h2 className="Offer-heading">
            <strong className="ninja-strong">В вашем городе еще нет Ниндзя-парк?</strong> <br className="Offer-br"/>
            Мы отлично дополняем спортивные школы, <br className="Offer-br"/>
            спортзалы и фитнес-центры
          </h2>
          <div className="Offer-row">
            <div className="Offer-inputs">
              <div className="Offer-group">
                <div className="Offer-icon Offer-icon--user"></div>
                <label htmlFor="client-name-1" className="Offer-label">
                  Имя, Фамилия:
                </label>
                <input
                  id="client-name-1"
                  name="name"
                  type="text"
                  value={name}
                  placeholder="Виталий Миронов"
                  className="Offer-input"
                  onInput={handleChange}
                  required
                />
              </div>
              <div className="Offer-group">
                <div className="Offer-icon Offer-icon--phone"></div>
                <label htmlFor="client-tel-1" className="Offer-label">
                  Телефон:
                </label>
                <input
                  id="client-tel-1"
                  name="tel"
                  type="tel"
                  value={tel}
                  placeholder="+7 777 999 99 99"
                  className="Offer-input"
                  onInput={handleChange}
                  required
                />
              </div>
              <div className="Offer-group">
                <div className="Offer-icon Offer-icon--mail"></div>
                <label htmlFor="client-email-1" className="Offer-label">
                  Email:
                </label>
                <input
                  id="client-email-1"
                  name="email"
                  type="email"
                  value={email}
                  placeholder="vitaliy.mironov@gamil.com"
                  className="Offer-input"
                  onInput={handleChange}
                  required
                />
              </div>
              <div className="Offer-order">
                <Button className="Offer-send">
                  <span className="Offer-decoration-1"></span>
                  Отправить на почту
                </Button>
              </div>
            </div>
            <div className="Offer-park">
              <div className="Offer-decoration-5"/>
              <div
                className="Offer-park-image"
                style={{backgroundImage: `url(${parkImage})`}}
              />
              <div className="Offer-decoration-2"/>
              <div className="Offer-decoration-3"/>
              <div className="Offer-decoration-4"/>
            </div>
          </div>
        </form>
      </Container>
    </Screen>
  )
}

export default Offer
