import { h } from 'preact'
import Text from '../components/Text'
import Button from '../components/Button'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import './Benefits.sass'

const showForm =  (source) => ({
  type: 'showForm',
  payload: source
})

const Benefits = ({dispatch}) => (
  <Screen id="benefits" className="Benefits">
    <div className="Benefits-decoration-1"></div>
    <div className="Benefits-decoration-2"></div>
    <div className="Benefits-decoration-3"></div>
    <div className="Benefits-decoration-4"></div>
    <Container className="Benefits-inner">
      <div className="Benefits-main">
        <Heading bar="right">
          Какие  выгоды от <br/>
          владения таким парком?
        </Heading>
        <Text className="Benefits-text">
          Приняв решение приобрести комплекс <br className="Benefits-br-t"/>
          ниндзя-курса, вы, как организаторы, инвестируете <br className="Benefits-br-t"/>
          в невероятно прибыльный и ежегодно развивающийся <br className="Benefits-br-t"/>
          сектор рынка услуг активного отдыха.
        </Text>
        <p className="Benefits-note">
          *средняя проходимость 30 чел.час на средний чек 300 руб.чел
        </p>
      </div>
      <ul className="Benefits-list">
        <li className="Benefits-item">
          <div className="Benefits-border"></div>
          <div className="Benefits-benefit">
            Ежегодный доход <br/>
            в среднем <strong className="ninja-strong">5&nbsp;000&nbsp;000&nbsp;р</strong>
          </div>
          <div className="Benefits-decoration-5"></div>
        </li>
        <li className="Benefits-item">
          <div className="Benefits-border"></div>
          <div className="Benefits-benefit">
            Современный, стильный, долговечный, <br className="Benefits-br-2"/>
            безопасный бизнес-проект, который <br className="Benefits-br-2"/>
            окупиться за 10-12 месяцев.
          </div>
        </li>
      </ul>
      <div className="Benefits-order">
        <Button className="Benefits-cta" onClick={() => dispatch(showForm('Какие выгоды / заказать под ключ'))}>
          <span className="Benefits-shuriken"></span>
          Заказать под ключ
        </Button>
      </div>
    </Container>
  </Screen>
)

export default Benefits
