import {useState, useEffect} from 'preact/hooks'

function useLazyTrigger(ref, callback) {
  useEffect(() => {
    function waitForScroll(event) {
      if (ref.current !== null && ref.current.base !== null) {
        const {offsetTop} = ref.current.base
        if (window.scrollY > offsetTop - 1500) {
          window.removeEventListener('scroll', waitForScroll)
          callback()
        }
      }
    }
    if (typeof window.scrollY !== 'undefined') {
      window.addEventListener('scroll', waitForScroll)
      waitForScroll()
    }
  }, [])
}

export default useLazyTrigger
