import { h } from 'preact'
import {useState} from 'preact/hooks'
import Button from '../components/Button'
import './PopupMessage.sass'

const PopupMessage = ({message, dispatch, isOpen}) => {
  const [isClosing, setIsClosing] = useState(false)

  function handleClose(event) {
    event.preventDefault()
    if (message.type && message.type === 'success') {
      dispatch({type: 'closeForm'})
    }
    setIsClosing(true)
    setTimeout(() => {
      dispatch({type: 'closeMessage'})
      setIsClosing(false)
    }, 400)
  }

  function handleClick(event) {
    event.stopPropagation()
  }

  return (
    <div className={getClassName(isOpen, isClosing)} onClick={handleClose}>
      <div className="PopupMessage-window" onClick={handleClick}>
        <div className="PopupMessage-content">
          <h2 className="PopupMessage-heading">
            {message.title}
          </h2>
          <div className="PopupMessage-text">
            {message.description}
          </div>
          <Button className="PopupMessage-close" onClick={handleClose}>
            Закрыть
          </Button>
        </div>
      </div>
    </div>
  )
}

function getClassName(isOpen, isClosing) {
  if (isOpen) {
    return isClosing
      ? 'PopupMessage PopupMessage--closing'
      : 'PopupMessage PopupMessage--open'
  }
  return 'PopupMessage'
}

export default PopupMessage
