import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Heading.sass'

const mods = ['heading', ['main', 'shaded', 'centered'], ['bar']]

const Heading = ({children, ...props}) => {
  const className = buildClass(style, props, mods)
  return props.main ? (
    <h1 className={className}>
      {children}
    </h1>
  ) : (
    <h2 className={className}>
      {children}
    </h2>
  )
}

export default Heading
