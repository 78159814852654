import { h } from 'preact'
import {useState, useEffect} from 'preact/hooks'
import Text from '../components/Text'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import aboutVideo from '../assets/about.mp4'
import './About.sass'

const About = () => {
  return (
    <Screen id="about" className="About">
      <div className="About-decoration-2"></div>
      <div className="About-decoration-1"></div>
      <Container className="About-inner">
        <div className="About-main">
          <Heading bar="right">
            Что такое Ниндзя-Парк?
          </Heading>
          <Text className="About-text" branded>
            Это довольно  популярный формат активного отдыха <br className="About-br"/>
            детей и взрослых в РФ, Европе и Америке. <br className="About-br"/>
            Ниндзя-парк представляет собой комплекс <br className="About-br"/>
            различных препятствий,  комфортных и безопасных, <br className="About-br"/>
            расположенных на небольшой высоте.
          </Text>
        </div>
        <div className="About-carousel">
          <div className="About-decoration-3"></div>
          <div className="About-decoration-4"></div>
          <div className="About-decoration-5"></div>
          <div className="About-showcase">
            <video className="About-video" width="100%" height="auto" loop="loop" autoplay="autoplay" preload="auto" muted>
              <source src={aboutVideo}></source>
            </video>
          </div>
        </div>
      </Container>
    </Screen>
  )
}

export default About
