import { h } from 'preact'
import {useRef, useEffect} from 'preact/hooks'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import useCarousel from '../utils/carousel'
import items from '../data/cases'
import './Done.sass'

const Done = () => {
  const carousel = useCarousel(items)
  const autoplay = useRef(false)
  const container = useRef(null)

  useEffect(() => {
    function nextSlide() {
      carousel.setCurrent(current => {
        if (current < items.length - 1) {
          if (current < items.length - 2) {
            setTimeout(nextSlide, 3000)
          }
          return current + 1
        }
        return current
      })
    }

    function waitForScroll(event) {
      if (container.current !== null && container.current.base) {
        const {offsetTop} = container.current.base
        if (window.scrollY > offsetTop - 200) {
          window.removeEventListener('scroll', waitForScroll)
          if (!autoplay.current) {
            autoplay.current = true
            setTimeout(nextSlide, 3000)
          }
        }
      }
    }

    window.addEventListener('scroll', waitForScroll)
  }, [])

  return (
    <Screen ref={container} id="done" className="Done">
      <div className="Done-decoration-6"></div>
      <Container className="Done-inner">
        <Heading bar="right">
          Реализованные проекты
          <span className="Done-decoration-7"></span>
        </Heading>
      </Container>
      <div className="Done-carousel">
        <div className="Done-window">
          <div className="Done-items">
            <div className="Done-item">
              <Container className="Done-item-inner">
                <div className="Done-preview">
                  <div className="Done-decoration-1"></div>
                  <div
                    className="Done-preview-images"
                    onTouchStart={carousel.handleTouchStart}
                    onTouchMove={carousel.handleTouchMove}
                    onTouchEnd={carousel.handleTouchEnd}
                  >
                    <div className="Done-images" style={{
                      width: `${100 * items.length}%`,
                      transform: `translateX(${carousel.offset}%) translateX(${carousel.isMoving ? carousel.delta : 0}px)`,
                      transition: carousel.isMoving ? 'none' : 'transform ease-out 0.5s'
                    }}>
                      {items.map((src, index) => (
                        <picture key={index} style={{
                          width: `${100 / items.length}%`
                        }}>
                          <source srcset={src.webp} type="image/webp"/>
                          <source srcset={src.jpg} type="image/jpeg"/>
                          <img src={src.jpg} alt={index + 1} className="Done-image" loading="lazy"/>
                        </picture>
                      ))}
                    </div>
                  </div>
                  <div className="Done-decoration-2"></div>
                  <div className="Done-decoration-3"></div>
                  <div className="Done-decoration-4"></div>
                </div>
                <div className="Done-decoration-5"></div>
                <div className="Done-content">
                  <h3 className="Done-title">
                    Ниндзя-парк в <strong className="ninja-strong">Омске</strong>
                  </h3>
                  <ul className="Done-list">
                    <li className="Done-info">
                      Построили за <strong>45 дней</strong>
                    </li>
                    <li className="Done-info">
                      Площадь <strong>300 м2</strong>
                    </li>
                    <li className="Done-info">
                      Стоимость <strong>3,5 млн рублей</strong>
                    </li>
                  </ul>
                </div>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  )
}

export default Done
