import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Text.sass'

const Text = ({children, ...props}) => (
  <p className={buildClass(style, props, ['text', null, ['branded']])}>
    {children}
  </p>
)

export default Text
