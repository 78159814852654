import { h } from 'preact'
import {buildClass} from '../utils/classBuilder'
import style from './Range.sass'

const Range = ({id, name, min, max, value, onChange, ...props}) => {
  return (
    <div className={buildClass(style, props, ['range'])}>
      <input
        id="calc-width"
        type="range"
        min={min}
        max={max}
        name={name}
        value={value}
        className={style.input}
        onInput={onChange}
      />
      <div className={style.scale}>
        <span className={style.min}>{min}</span>
        <span className={style.max}>{max}</span>
      </div>
      <div className={style.value} style={{
        left: `${(value - min) / (max - min) * 100}%`,
        marginLeft: `${7 + (min - value) / (max - min) * 10}px`
      }}>
        {value}
      </div>
    </div>
  )
}

export default Range
