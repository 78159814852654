export default [
  {
    original: {
      jpg: require('./1.jpg').default,
      webp: require('./1.webp').default
    },
    thumb: {
      jpg: require('./1-thumb.jpg').default,
      webp: require('./1-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./2.jpg').default,
      webp: require('./2.webp').default
    },
    thumb: {
      jpg: require('./2-thumb.jpg').default,
      webp: require('./2-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./3.jpg').default,
      webp: require('./3.webp').default
    },
    thumb: {
      jpg: require('./3-thumb.jpg').default,
      webp: require('./3-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./4.jpg').default,
      webp: require('./4.webp').default
    },
    thumb: {
      jpg: require('./4-thumb.jpg').default,
      webp: require('./4-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./5.jpg').default,
      webp: require('./5.webp').default
    },
    thumb: {
      jpg: require('./5-thumb.jpg').default,
      webp: require('./5-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./6.jpg').default,
      webp: require('./6.webp').default
    },
    thumb: {
      jpg: require('./6-thumb.jpg').default,
      webp: require('./6-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./7.jpg').default,
      webp: require('./7.webp').default
    },
    thumb: {
      jpg: require('./7-thumb.jpg').default,
      webp: require('./7-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./8.jpg').default,
      webp: require('./8.webp').default
    },
    thumb: {
      jpg: require('./8-thumb.jpg').default,
      webp: require('./8-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./9.jpg').default,
      webp: require('./9.webp').default
    },
    thumb: {
      jpg: require('./9-thumb.jpg').default,
      webp: require('./9-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./10.jpg').default,
      webp: require('./10.webp').default
    },
    thumb: {
      jpg: require('./10-thumb.jpg').default,
      webp: require('./10-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./11.jpg').default,
      webp: require('./11.webp').default
    },
    thumb: {
      jpg: require('./11-thumb.jpg').default,
      webp: require('./11-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./12.jpg').default,
      webp: require('./12.webp').default
    },
    thumb: {
      jpg: require('./12-thumb.jpg').default,
      webp: require('./12-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./13.jpg').default,
      webp: require('./13.webp').default
    },
    thumb: {
      jpg: require('./13-thumb.jpg').default,
      webp: require('./13-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./14.jpg').default,
      webp: require('./14.webp').default
    },
    thumb: {
      jpg: require('./14-thumb.jpg').default,
      webp: require('./14-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./15.jpg').default,
      webp: require('./15.webp').default
    },
    thumb: {
      jpg: require('./15-thumb.jpg').default,
      webp: require('./15-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./16.jpg').default,
      webp: require('./16.webp').default
    },
    thumb: {
      jpg: require('./16-thumb.jpg').default,
      webp: require('./16-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./17.jpg').default,
      webp: require('./17.webp').default
    },
    thumb: {
      jpg: require('./17-thumb.jpg').default,
      webp: require('./17-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./18.jpg').default,
      webp: require('./18.webp').default
    },
    thumb: {
      jpg: require('./18-thumb.jpg').default,
      webp: require('./18-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./19.jpg').default,
      webp: require('./19.webp').default
    },
    thumb: {
      jpg: require('./19-thumb.jpg').default,
      webp: require('./19-thumb.webp').default
    }
  },
  {
    original: {
      jpg: require('./20.jpg').default,
      webp: require('./20.webp').default
    },
    thumb: {
      jpg: require('./20-thumb.jpg').default,
      webp: require('./20-thumb.webp').default
    }
  }
]
