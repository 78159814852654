export default [
  {
    jpg: require('./1.jpg').default,
    webp: require('./1.webp').default
  },
  {
    jpg: require('./2.jpg').default,
    webp: require('./2.webp').default
  },
  {
    jpg: require('./3.jpg').default,
    webp: require('./3.webp').default
  },
  {
    jpg: require('./4.jpg').default,
    webp: require('./4.webp').default
  },
  {
    jpg: require('./5.jpg').default,
    webp: require('./5.webp').default
  },
  {
    jpg: require('./6.jpg').default,
    webp: require('./6.webp').default
  },
  {
    jpg: require('./7.jpg').default,
    webp: require('./7.webp').default
  }
]
