import { h } from 'preact'
import {useRef} from 'preact/hooks'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import useLazyTrigger from '../utils/lazyTrigger'
import './Steps.sass'

const Steps = () => {
  const ref = useRef(null)

  useLazyTrigger(ref, () => import('./StepsLazy.sass'))

  return (
    <Screen id="steps" ref={ref} className="Steps">
      <div className="Steps-decoration-1"></div>
      <div className="Steps-decoration-2"></div>
      <div className="Steps-decoration-3"></div>
      <Container className="Steps-inner">
        <Heading bar="right">
          Как мы работаем?
        </Heading>
        <ol className="Steps-list">
          <li className="Steps-step">
            <div className="Steps-border"></div>
            <div className="Steps-number"></div>
            <div className="Steps-icon Steps-icon--pen"></div>
            <div className="Steps-text">
              Создаем проект и возможности <br/>
              вашего помещения.
            </div>
          </li>
          <li className="Steps-step">
            <div className="Steps-border"></div>
            <div className="Steps-number"></div>
            <div className="Steps-icon Steps-icon--blueprints"></div>
            <div className="Steps-text">
              Разрабатываем проект изучая <br/>
              вазможности
            </div>
          </li>
          <li className="Steps-step">
            <div className="Steps-border"></div>
            <div className="Steps-number"></div>
            <div className="Steps-icon Steps-icon--dollar"></div>
            <div className="Steps-text">
              Рассчитываем окупаемость проекта
            </div>
          </li>
          <li className="Steps-step">
            <div className="Steps-border"></div>
            <div className="Steps-number"></div>
            <div className="Steps-icon Steps-icon--trowel"></div>
            <div className="Steps-text">
              Изготавливаем и монтируем конструкцию
            </div>
          </li>
          <li className="Steps-step">
            <div className="Steps-border"></div>
            <div className="Steps-number"></div>
            <div className="Steps-icon Steps-icon--helmet"></div>
            <div className="Steps-text">
              Собираем команду на месте
            </div>
          </li>
          <li className="Steps-step">
            <div className="Steps-border"></div>
            <div className="Steps-number"></div>
            <div className="Steps-icon Steps-icon--chart"></div>
            <div className="Steps-text">
              Поддерживаем на старте
            </div>
          </li>
        </ol>
      </Container>
    </Screen>
  )
}

export default Steps
