import { h } from 'preact'
import {useReducer} from 'preact/hooks'
import Main from '../screens/Main'
import About from '../screens/About'
import Projects from '../screens/Projects'
import Benefits from '../screens/Benefits'
import Done from '../screens/Done'
import Steps from '../screens/Steps'
import Calc from '../screens/Calc'
import Offer from '../screens/Offer'
import Numbers from '../screens/Numbers'
import Requirements from '../screens/Requirements'
import Delivery from '../screens/Delivery'
import Contacts from '../screens/Contacts'
import PopupContainer from '../screens/PopupContainer'
import PopupForm from '../screens/PopupForm'
import PopupMessage from '../screens/PopupMessage'
import LightBox from '../screens/LightBox'

const initialState = {
  message: {
    type: 'success',
    title: 'Заявка отправлена!',
    description: 'Уже скоро с вами свяжется наш менеджер'
  },
  lightbox: {
    current: 0,
    items: [],
    hasWebP: false
  },
  form: null,
  isFormOpen: false,
  isMessageOpen: false,
  isLightBoxOpen: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'showForm':
      return {...state, form: action.payload, isFormOpen: true}
    case 'showMessage':
      return {...state, message: action.payload, isMessageOpen: true}
    case 'closeForm':
      return {...state, isFormOpen: false}
    case 'closeMessage':
      return {...state, isMessageOpen: false}
    case 'showLightBox':
      return {...state, lightbox: action.payload, isLightBoxOpen: true}
    case 'closeLightBox':
      return {...state, isLightBoxOpen: false}
    default:
      return state
  }
}

const App = () => {
  const [app, dispatch] = useReducer(reducer, initialState)
  return (
    <div className="App">
      <Main dispatch={dispatch}/>
      <About dispatch={dispatch}/>
      <Projects dispatch={dispatch}/>
      <Benefits dispatch={dispatch}/>
      <Done dispatch={dispatch}/>
      <Steps dispatch={dispatch}/>
      <Calc dispatch={dispatch}/>
      <Offer dispatch={dispatch}/>
      <Numbers dispatch={dispatch}/>
      <Requirements dispatch={dispatch}/>
      <Delivery dispatch={dispatch}/>
      <Contacts dispatch={dispatch}/>
      <PopupContainer isOpen={isPopupOpen(app)}>
        <PopupForm
          form={app.form}
          isFormOpen={app.isFormOpen}
          isPopupOpen={app.isMessageOpen}
          dispatch={dispatch}
        />
        <PopupMessage
          message={app.message}
          isOpen={app.isMessageOpen}
          dispatch={dispatch}
        />
        <LightBox
          lightbox={app.lightbox}
          isOpen={app.isLightBoxOpen}
          dispatch={dispatch}
        />
      </PopupContainer>
    </div>
  )
}

function isPopupOpen(state) {
  return state.isFormOpen || state.isMessageOpen || state.isLightBoxOpen
}

export default App
