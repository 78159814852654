import { h } from 'preact'
import {useState, useEffect} from 'preact/hooks'
import './LightBox.sass'

const LightBox = ({lightbox, dispatch, isOpen}) => {
  const initialCurrent = lightbox.current
  const [current, setCurrent] = useState(initialCurrent)
  const [offset, setOffset] = useState(-1)
  const [isClosing, setIsClosing] = useState(false)
  const [isReady, setIsReady] = useState(true)
  const items = getGalleryWindow(lightbox, current)

  useEffect(() => {
    setCurrent(initialCurrent)
  }, [initialCurrent])

  function handleLeft(event) {
    if (isReady) {
      setOffset(0)
      setTimeout(() => {
        setIsReady(false)
      }, 400)
      setTimeout(() => {
        setCurrent(current => current > 0 ? current - 1 : lightbox.items.length - 1)
        setOffset(-1)
      }, 425)
      setTimeout(() => {
        setIsReady(true)
      }, 450)
    }
  }

  function handleRight(event) {
    if (isReady) {
      setOffset(-2)
      setTimeout(() => {
        setIsReady(false)
      }, 400)
      setTimeout(() => {
        setCurrent(current => current < lightbox.items.length - 1 ? current + 1 : 0)
        setOffset(-1)
      }, 425)
      setTimeout(() => {
        setIsReady(true)
      }, 450)
    }
  }

  function handleClose(event) {
    event.preventDefault()
    setIsClosing(true)
    setTimeout(() => {
      dispatch({type: 'closeLightBox'})
      setIsClosing(false)
    }, 400)
  }

  function handleClick(event) {
    event.stopPropagation()
  }

  useEffect(() => {
    function handleKeyUp(event) {
      if (event.key === 'Escape') {
        handleClose(event)
      }
    }

    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <div className={getClassName(isOpen, isClosing)} onClick={handleClose}>
      <div className="LightBox-window" onClick={handleClick}>
        <div className="LightBox-carousel">
          <div className="LightBox-preview">
            <div className="LightBox-items" style={{
              transition: isReady ? 'transform ease-out 0.4s' : 'none',
              transform: `translateX(${33.3333 * offset}%)`
            }}>
              {items.map(item => (
                <div className="LightBox-image" style={{
                  backgroundImage: lightbox.hasWebP
                  ? `url(${item.original.webp})`
                  : `url(${item.original.jpg})`
                }}/>
              ))}
            </div>
          </div>
          <div className="Projects-left" onClick={handleLeft}></div>
          <div className="Projects-right" onClick={handleRight}></div>
        </div>
      </div>
    </div>
  )
}

function getClassName(isOpen, isClosing) {
  if (isOpen) {
    return isClosing
      ? 'LightBox LightBox--closing'
      : 'LightBox LightBox--open'
  }
  return 'LightBox'
}

function getGalleryWindow({items}, current) {
  const offset = items.length + current - 1
  return items.concat(items).concat(items).slice(offset, offset + 3)
}

export default LightBox
