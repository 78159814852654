import { h } from 'preact'
import {useRef} from 'preact/hooks'
import Button from '../components/Button'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import useLazyTrigger from '../utils/lazyTrigger'
import './Contacts.sass'

const showForm =  (source) => ({
  type: 'showForm',
  payload: source
})

const Contacts = ({dispatch}) => {
  const ref = useRef(null)

  useLazyTrigger(ref, () => import('./ContactsLazy.sass'))

  return (
    <Screen id="contacts" ref={ref} className="Contacts">
      <div className="Contacts-decoration-5"></div>
      <Container className="Contacts-inner">
        <Heading bar="right" className="Contacts-heading-1">
          Есть вопросы? Давайте обсудим!
        </Heading>
        <div className="Contacts-content">
          <div className="Contacts-decoration-4"></div>
          <div className="Contacts-figure">
            <div className="Contacts-decoration-2"></div>
            <div className="Contacts-decoration-3"></div>
            <ul className="Contacts-questions">
              <li className="Contacts-question">
                <span className="Contacts-text">
                  Что больше нравиться детям?
                </span>
              </li>
              <li className="Contacts-question">
                <span className="Contacts-text">
                  Что мне выбрать?
                </span>
              </li>
              <li className="Contacts-question">
                <span className="Contacts-text">
                  Какая будет стоимость?
                </span>
              </li>
            </ul>
            <div className="Contacts-note">
              На эти и много других вопросов <br/>
              мы с легкостью ответим!
            </div>
          </div>
          <div className="Contacts-info">
            <h3 className="Contacts-heading">
              Контакты
            </h3>
            <ul className="Contacts-list">
              <li className="Contacts-item">
                +7(3452)45-88-19 <br/>
                +7952-680-72-30
              </li>
              <li className="Contacts-item">
                ninja.park@mail.ru
              </li>
            </ul>
            <div className="Contacts-cta">
              <Button className="Contacts-action" onClick={() => dispatch(showForm('Контакты / связаться с командой'))}>
                <span className="Contacts-decoration-1"></span>
                Связаться с командой Ниндзя-Парк
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </Screen>
  )
}

export default Contacts
