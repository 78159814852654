import { h } from 'preact'
import {useRef} from 'preact/hooks'
import Text from '../components/Text'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import useLazyTrigger from '../utils/lazyTrigger'
import './Delivery.sass'

const Delivery = () => {
  const ref = useRef(null)

  useLazyTrigger(ref, () => import('./DeliveryLazy.sass'))

  return (
    <Screen id="delivery" ref={ref} className="Delivery">
      <Container className="Delivery-inner">
        <div className="Delivery-figure">
          <div className="Delivery-caption">
            Тюмень
          </div>
          <div className="Delivery-decoration-2"></div>
          <div className="Delivery-decoration-3"></div>
          <div className="Delivery-city"></div>
          <div className="Delivery-decoration-1"></div>
        </div>
        <div className="Delivery-content">
          <div className="Delivery-block">
            <Heading bar="left">
              Немного о доставке
            </Heading>
            <Text className="ninja-strong">
              Наше производство находится в Тюмени.
              <span className="Delivery-decoration-4"></span>
            </Text>
            <Text>
              Вы можете самостоятельно забрать покупку <br className="Delivery-br"/>
              из точки самовывоза или заказать доставку <br className="Delivery-br"/>
              до места установки.
            </Text>
            <Text>
              Собрать конструкции не сложно, ведь мы <br className="Delivery-br"/>
              создаем все необходимые инструкции.
            </Text>
          </div>
        </div>
      </Container>
    </Screen>
  )
}

export default Delivery
