import { h } from 'preact'
import {useRef} from 'preact/hooks'
import {useState} from 'preact/hooks'
import Button from '../components/Button'
import Screen from '../components/Screen'
import Heading from '../components/Heading'
import Container from '../components/Container'
import Range from '../components/Range'
import useLazyTrigger from '../utils/lazyTrigger'
import './Calc.sass'

const showForm =  (source) => ({
  type: 'showForm',
  payload: source
})

const Calc = ({dispatch}) => {
  const [width, setWidth] = useState(30)
  const [length, setLength] = useState(40)
  const parkCost = parseInt(width) * parseInt(length) * 11000
  const ref = useRef(null)

  useLazyTrigger(ref, () => import('./CalcLazy.sass'))

  function handleChange(event) {
    const {name, value} = event.target
    if (name === 'width') {
      setWidth(value)
    } else if (name === 'length') {
      setLength(value)
    }
  }

  function handleSubmit(event) {
    event.preventDefault()
    dispatch(showForm(`Калькулятор / ${width}x${length}`))
  }

  return (
    <Screen id="calc" ref={ref} className="Calc">
      <Container className="Calc-inner">
        <Heading>
          Рассчитать стоимость с<br className="Calc-br"/>
          помощью калькулятора
        </Heading>
        <form className="Calc-form" onSubmit={handleSubmit}>
          <div className="Calc-inputs">
            <div className="Calc-group">
              <label htmlFor="calc-width" className="Calc-label">
                Ширина помещения
              </label>
              <Range
                id="calc-width"
                min="4"
                max="100"
                name="width"
                value={width}
                className="Calc-range"
                onChange={handleChange}
              />
            </div>
            <div className="Calc-group">
              <label htmlFor="calc-length" className="Calc-label">
                Длина помещения
              </label>
              <Range
                id="calc-length"
                min="4"
                max="100"
                name="length"
                value={length}
                className="Calc-range"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="Calc-results">
            <div className="Calc-decoration-4"></div>
            <div className="Calc-decoration-5"></div>
            <div className="Calc-border"></div>
            <div className="Calc-decoration-2"></div>
            <div className="Calc-decoration-3"></div>
            <div className="Calc-results-value">{parkCost.toLocaleString() + ' р.'}</div>
            <div className="Calc-decoration-1"></div>
            <div className="Calc--cta">
              <Button inverted>
                Помочь с выбором?
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </Screen>
  )
}

export default Calc
